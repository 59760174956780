import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toasterUpdate } from "../utils/toasterUpdate";
import UserProfile from "../userProfile";
import "./buyers.css";

const Profile = () => {
  const [user, setUser] = useState(false);
  const navigate = useNavigate();

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    if (!result.data.complete) {
      toast.dismiss(toastID);
      navigate("/complete");
    }

    setUser((prev) => result.data.user);
    toast.dismiss(toastID);
  };

  useEffect(() => {
    syncProfile();
  }, []);

  return (
    <>
      <UserProfile />
      <h4
        className="my-3"
        style={{
          fontWeight: "400",
          color: "#767676",
          fontFamily: "'Inter', sans-serif !important",
        }}
      >
        Users
      </h4>

      {user && (
        <div className="buyers-profile-box">
          <div className="buyers-profile-banner">
            <img
              src={"/images/bban.png"}
              alt={user.vendor?.company}
              onError={(e) => {
                e.target.src = "/images/bban.png";
              }}
            />
            {/* <div className="edit-circle"><CreateIcon /></div> */}
          </div>
          <div className="buyers-dp">
            <img
              src={user.doc[0] ? user.doc[0].url : "/images/bban.png"}
              alt={user.vendor?.company}
              onError={(e) => {
                e.target.src = "/no-image.png";
              }}
              className="img-fluid"
            />
          </div>
          {/* <div className="edb">
            <button className="conbtn-g">Edit Details</button>
          </div> */}
          <div className="buyers-name">{user.name}</div>
          {/* <div className="greybox df aic"><ApartmentIcon className="greybox-i" /> Umiya Agro Industries</div> */}

          <div className="greybox df aic">Name : {user?.name}</div>
          <div className="greybox df aic">Email ID : {user?.email}</div>
          <div className="greybox df aic">GST No. : {user?.vendor.gst}</div>
          
          <div className="greybox df aic">
            Explosive Licence No. : {user?.vendor.explosive_licence}
          </div>
          <div className="greybox df aic">Address : {user?.vendor.address}</div>
          <div className="greybox df aic">Contact : {user?.phone}</div>
          <div className="greybox df aic">
            Drug Licence No. : {user?.vendor.drug_licence}
          </div>
          <div className="greybox df aic">
            Prohibition Excise : {user?.vendor.prohibition}
          </div>

          {user?.kyc.map((el, index) => {
                return (
                  <div className="greybox df aic">
                    {el.label.replace("_", "")} : {el.value}
                  </div>
                );
              })}
        </div>
      )}
    </>
  );
};

export default Profile;
