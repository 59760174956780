import React, { useState, useEffect } from 'react';
import './mobilenav.css';
import { NavLink, Link } from 'react-router-dom';
import { Mobilemenu1, Mobilemenu2, Mobilemenu3 } from './mobilemenu';
import { IconContext } from 'react-icons';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { HiUserCircle } from 'react-icons/hi';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
// import UserProfile from '../userProfile/index'

const Mobilenav = () => {


    useEffect(() => {

        $('#profile_id').click(function () {
            $('.userProfile').slideDown();
            // $('.notification').slideUp();
            // $('.NotiPro_Outer').show();
        });
        $('.profile,.order,.wishlist,.logout').click(function () {
            $('.userProfile').hide();
        });

    }, []);

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    const shoot = () => {
        window.location.href = "https://rasayankart.in/"
      }

    return (

        <>

            <div className='mobileNav'>
                <IconContext.Provider value={{ color: '#000' }}>
                    <div className='navbar '>
                        <div className='log-icon'>
                            <NavLink to='#' className='menu-bars'>
                                <FaIcons.FaBars onClick={showSidebar} />
                            </NavLink>
                            <Link to='/' className='logo'>
                                <h3 className='logo-txt'>Rasayan <span>Kart</span></h3>
                            </Link>
                        </div>
                        <div className='useraction'>
                            <IoIosNotificationsOutline className='noti' />
                            <HiUserCircle className='user' id='profile_id' />
                        </div>
                    </div>


                    <div className='userProfile'>
                        <ul>
                            <li className='profile'>Profile</li>
                            <li className='order'>Orders</li>
                            <li className='wishlist'>Wishlist</li>
                            <li className='logout'>Logout</li>
                        </ul>
                    </div>
                    <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                        <ul className='nav-menu-items' onClick={showSidebar}>
                            <li className='navbar-logo'>
                                <Link to='/'> <img src="../images/mob-logo.png" alt="mob-logo" /></Link>
                                <NavLink to='#' className='menu-bars'>
                                    <AiIcons.AiOutlineClose />
                                </NavLink>
                            </li>
                            {Mobilemenu1.map((item, index) => {
                                return (
                                    <li key={index} className={item.cName}>
                                        <NavLink to={item.path}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </NavLink>
                                    </li>
                                );
                            })}
                            <h3 className='account'>My Account</h3>
                            <div className='borders'>
                                {Mobilemenu2.map((item, index) => {
                                    return (
                                        <li key={index} className={item.cName}>
                                            <NavLink to={item.path}>
                                                {item.icon}
                                                <span>{item.title}</span>
                                            </NavLink>
                                        </li>

                                    );

                                })};
                            </div>
                            <h3></h3>
                            {Mobilemenu3.map((item, index) => {
                                    return (
                                        <li key={index} className={item.cName}>
                                            <NavLink to={item.path}>
                                                {item.icon}
                                                <span>{item.title}</span>
                                            </NavLink>
                                        </li>

                                    );

                                })};
                            <div className='mbmnuvr'>
                                <div onClick={shoot} className="form-check justify-content-around form-switch ps-0 d-flex align-items-center">
                                    <label className="form-check-label float-start" htmlFor="switchtoseller"> Buyer</label>
                                    <input className="form-check-input  float-start" style={{ marginLeft: "10px" }} type="checkbox" role="switch" id="switchtoseller" />
                                    <label className="form-check-label float-start" htmlFor="switchtoseller">Seller</label>
                                </div>
                            </div>
                        </ul>
                    </nav>
                </IconContext.Provider>
                
            </div>
        </>




    )
}

export default Mobilenav