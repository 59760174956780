import React, { useEffect, useRef, useState } from "react";
import UserProfile from "../userProfile";
import PaymentChart from "./paymentChart";
import PaymentTable from "./paymentTable";
import OrderApi from "./orderApi";
import Filter from "../filter/Filter";
import { BsWallet2 } from "react-icons/bs";
import { MdErrorOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import formatter from "../utils/formatter";
import { toasterUpdate } from "../utils/toasterUpdate";

const Payments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [data, setData] = useState({});
  const [transaction, setTransaction] = useState([]);

  const closeRef = useRef(null);
  const formRef = useRef(null);
  const [amount, setAmount] = useState("");


  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/seller/payments?page=${currentPage}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    // console.log(result);
    if (result.error) {
      toast.error(result.message);
      return;
    }

    setData(() => result.data);
    setTransaction(result.data.transaction?.data);
    setLastPage(result.data.transaction?.last_page);
  };

  function closeModal() {
    closeRef.current.click();
  }

  const createApi = async (e) => {
    try {
      e.preventDefault();

      const toastID = toast.loading("Creating...");
      const formData = new FormData();

      formData.append("amount", amount);

      // const token = localStorage.getItem("token_web");
      // const response = await fetch(`${baseUrl}/seller/wallet/request`, {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      //   body: formData,
      // });

      // const result = await response.json();

      if (amount > data.green) {
        toasterUpdate(toastID, 'Withdrawable balance is not sufficient.', "error");
        return;
      }

      formRef.current.reset();
      closeModal();
      toasterUpdate(toastID, 'Withdraw request has been sent.', "success");
    } catch (error) {
      toast.error("Something Technical Error");
    }
  };

  useEffect(() => {
    fetchApi();
    return () => toast.dismiss();
  }, []);

  return (
    <>
      <UserProfile />
      <div className="row mt-4">
        <div className="col-12">
          <div className="input-group mb-3 topSerrch21">
            <input
              type="text"
              className="form-control"
              id="inputID"
              placeholder=""
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <span className="input-group-text" id="basic-addon1">
              <img src="/images/search.png" height={18} />
            </span>
          </div>
        </div>
      </div>

      <div className="widgets">
        <div className={"wallet1"}>
          <p className="type">
            RK Wallet Credit <MdErrorOutline />
          </p>
          <h6>
            <BsWallet2 />
          </h6>
          <h1>{formatter.format(data?.wallet?.sanctioned_amount || 0)}</h1>
          {/* <Link>{val.transaction}</Link> */}
        </div>
        <div className={"wallet2"}>
          <p className="type">
            Issued PO’s <MdErrorOutline />
          </p>
          <h6>
            <BsWallet2 />
          </h6>
          <h1>{formatter.format(data.yellow || 0)}</h1>
          {/* <Link>{val.transaction}</Link> */}
        </div>
        <div className={"wallet3"}>
          <p className="type">
            Approved POs <MdErrorOutline />
          </p>
          <h6>
            <BsWallet2 />
          </h6>
          <h1>{formatter.format(data.orange || 0)}</h1>
          {/* <Link>{val.transaction}</Link> */}
        </div>
        <div className={"wallet4"}>
          <p className="type">
            Withdrawable <MdErrorOutline />
          </p>
          <h6>
            <BsWallet2 />
          </h6>
          <h1>{formatter.format(data.green || 0)}</h1>
          {/* <Link>{val.transaction}</Link> */}
        </div>
      </div>

      <Filter />

      <div className="flex-box justify-content-end">
        <button data-bs-toggle="modal"
          data-bs-target="#ticketModal">Withdraw Amount</button>
      </div>

      <div
        className="modal fade"
        id="ticketModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Withdraw Amount Request
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <small>Please fill desire amount to withdraw</small>

              <form onSubmit={createApi} ref={formRef}>
                <div className="mb-3 row">
                  <div className="col-md-12 col-12">
                    <input
                      type="text"
                      name="amount"
                      id="subject"
                      onInput={(e) => setAmount(e.target.value)}
                      required
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="float-end mt-2">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    ref={closeRef}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-1">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <PaymentTable data={transaction} />
    </>
  );
};

export default Payments;
