const SalesData = [
{
    id: 1,
    barSize:20,
    sales: 500,
    month:"Jan"
},
{
    id: 2,
    barSize:20,
    sales: 840,
    month:"Feb"
},
{
    id: 3,
    sales: 644,
    barSize:20,
    month:"Mar"
},
{
    id: 4,
    sales: 700,
    month:"Apr"
},
{
    id: 5,
    sales: 900,
    barSize:20,
    month:"May"
},
{
    id: 6,
    sales: 1004,
    barSize:20,
    month:"Jun"
},
{
    id: 7,
    sales: 804,
    barSize:20,
    month:"July"
},
{
    id: 8,
    sales: 1004,
    barSize:20,
    month:"Aug"
},
{
    id: 9,
    sales: 1104,
    barSize:20,
    month:"Sept"
},
{
    id: 10,
    sales: 704,
    barSize:20,
    month:"Oct"
},
{
    id: 11,
    sales: 1004,
    barSize:20,
    month:"Nov"
},
{
    id: 12,
    sales: 604,
    barSize:20,
    month:"Dec"
},
]

export default SalesData;