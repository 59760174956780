import React, { useEffect, useRef, useState } from "react";
import UserProfile from "../userProfile";
import RecentTransactions from "./recentTransactions";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";
import formatter from "../utils/formatter";

const Loan = () => {
  const [data, setData] = useState({});
  const [transaction, setTransaction] = useState([]);

  const closeRef = useRef(null);
  const formRef = useRef(null);

  const [amount, setAmount] = useState("");

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/seller/wallet/show`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    console.log(result);
    if (result.error) {
      toast.error(result.message);
      return;
    }

    setData(() => result.data);
    setTransaction(() => result.data.sanction_requests);
  };

  const createApi = async (e) => {
    try {
      e.preventDefault();

      const toastID = toast.loading("Creating...");
      const formData = new FormData();

      formData.append("amount", amount);

      const token = localStorage.getItem("token_web");
      const response = await fetch(`${baseUrl}/seller/wallet/request`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      formRef.current.reset();
      closeModal();
      toasterUpdate(toastID, result.message, "success");
    } catch (error) {
      toast.error("Something Technical Error");
    }
  };

  function closeModal() {
    closeRef.current.click();
  }

  useEffect(() => {
    fetchApi();
    return () => toast.dismiss();
  }, []);

  return (
    <>
      <UserProfile />
      <div className="row justify-content-end my-3 limebTnTwo202">
        {/* <a className='btn btn-primary mb-2'>Increase Credit  Limit</a> */}

        <a
          className="btn btn-primary mb-2"
          data-bs-toggle="modal"
          data-bs-target="#ticketModal"
        >
          Increase Credit Limit
        </a>
      </div>

      <div
        className="modal fade"
        id="ticketModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Increase Sanctioned Limit
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <small>Please Fill Desire Amount of Sanctioned Limit</small>

              <form onSubmit={createApi} ref={formRef}>
                <div className="mb-3 row">
                  <div className="col-md-12 col-12">
                    <input
                      type="text"
                      name="amount"
                      id="subject"
                      onInput={(e) => setAmount(e.target.value)}
                      required
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="float-end mt-2">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    ref={closeRef}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-1">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="walletouter">
            <p>Sanctioned Limit</p>
            <h3>{formatter.format(data?.wallet?.sanctioned_amount || 0)}</h3>
          </div>
        </div>
        <div className="col-md-4">
          <div className="walletouter">
            <p>Credit Limit</p>
            <h3>{formatter.format(data.wallet?.balance || 0)}</h3>
          </div>
        </div>
        <div className="col-md-4">
          <div className="walletouter">
            <p>Used Sanctioned Amount</p>
            <h3>{formatter.format((data?.wallet?.sanctioned_amount - data?.wallet?.balance) || 0)}</h3>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12  mb-3">
          <RecentTransactions data={transaction} />
        </div>
      </div>
    </>
  );
};

export default Loan;
