import React, { useState } from "react";
import UserProfile from "../userProfile";
import "./style.css";
import AllProducts from "./allProducts";
import AllProductApi from "./allProductApi";
import { Link } from "react-router-dom";
import CatelougeSearch from "../catelougeSearch/index";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";

const Catalouge = () => {
  useEffect((useEffect) => {
    $("#inputID").click(function () {
      $(".overLaySerchCateLouge").show();
    });
    $(".overLaySerchCateLouge, #basic-addon1, .searchproductCatePop").click(
      function () {
        $(".overLaySerchCateLouge").hide();
      }
    );
  }, []);

  const [product, setProduct] = useState(AllProductApi);
  return (
    <>
      <UserProfile />
      <CatelougeSearch />
      <div className="row mt-4">
        <div className="col-12 position-relative">
          <div className="input-group mb-3 topSerrch21">
            <input
              type="text"
              className="form-control InputIdSearchBoxInput"
              id="inputID"
              placeholder="Search Product"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <div className="overLaySerchCateLouge"></div>
            <span className="input-group-text" id="basic-addon1">
              <img src="/images/search.png" height={18} />
            </span>
          </div>
          
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="javascript:void(0)">
                  Delete All
                </a>
              </li>
            </ul>
          </div>
          {/* <button className="btn btn-light ms-1">+</button> */}
          {/* <button className="btn btn-primary float-end allOrderTwobn"></button> */}
          <div className="btn-group  float-end" role="group">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Add Product
            </button>
            <ul className="dropdown-menu">
              {/* <li>
                <Link className="dropdown-item" disabled={true} to="#">
                  Bulk Product Upload
                </Link>
              </li> */}
              <li>
                <Link className="dropdown-item" to="/product/add">
                  Single Product Upload
                </Link>
              </li>
            </ul>
            {/* <Link  className="btn btn-primary" to='/addProduct'>Add Product</Link> */}
            {/* <ul className="dropdown-menu">
              <li><Link  className="dropdown-item" to='/uploadBulk'>Bulk Product Upload</Link></li>
              <li><Link className="dropdown-item"  to='/addProduct'>Single Product Upload</Link></li>
            </ul> */}
          </div>
        </div>
      </div>
      <AllProducts product={product} />
    </>
  );
};

export default Catalouge;
