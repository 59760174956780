
import { ImUserTie } from 'react-icons/im';
import { GiGraduateCap } from 'react-icons/gi';
import { RiDashboardFill } from 'react-icons/ri';
import { FaImages } from 'react-icons/fa';
import { FaSuitcase } from 'react-icons/fa';
import { MdReviews } from 'react-icons/md';
import { BsBadgeCcFill } from 'react-icons/bs';

export const Mobilemenu1 = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <RiDashboardFill />,
    cName: 'nav-text'
  },
  {
    title: 'Orders',
    path: '/orders',
    icon: <GiGraduateCap />,
    cName: 'nav-text'
  },
  {
    title: 'Awaiting Responses',
    path: '/request-for-quote',
    icon: <BsBadgeCcFill />,
    cName: 'nav-text'
  },

];


export const Mobilemenu2 = [

  {
    title: 'All User',
    path: '/user',
    icon: <ImUserTie />,
    cName: 'nav-text'
  },
  {
    title: 'Add Sub Account',
    path: '/addsubaccount',
    icon: <FaImages />,
    cName: 'nav-text'
  },
];




export const Mobilemenu3 = [
  {
    title: 'Payments',
    path: '/payments',
    icon: <FaSuitcase />,
    cName: 'nav-text'
  },
  {
    title: 'Catalogue',
    path: '/catalouge',
    icon: <MdReviews />,
    cName: 'nav-text'
  },
  {
    title: 'Loan',
    path: '/loan',
    icon: <MdReviews />,
    cName: 'nav-text'
  },
  {
    title: 'Support',
    path: '/support',
    icon: <MdReviews />,
    cName: 'nav-text'
  }
]










