import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

const ResponseRFQ = () => {
    return (
        <>

            <div className='row'>
                <div className='col-md-9'>
                    <h3 style={{ fontSize: "20px" }}>Response RFQ</h3>
                    <p className='w-75'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. </p>
                </div>
                <div className='col-md-3'>
                    <img className='img-fluid responFaqImgMain' src='../images/Safe-with-money-and-a-bag-of-money.png' />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-5'>
                    <h4 className='mb-0' style={{ fontSize: "20px" }}>Per pieces product Price</h4>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-12 col-form-label">Excl of GST</label>
                        <div class="col-12">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                </div>
                <div className='col-md-5'>
                    <h4 className='mb-0' style={{ fontSize: "20px" }}>Per pieces product Price</h4>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-12 col-form-label">Example : 30</label>
                        <div class="col-12">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                </div>
                <div className='col-md-5 mt-2'>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-12 col-form-label">Product GST (%)</label>
                        <div class="col-12">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 border-rightside'>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-12 col-form-label">Packaging Pricing (INR)</label>
                        <div class="col-md-11">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-12 col-form-label">Logistics Price (INR) </label>
                        <div class="col-md-11">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-md-11 offset-md-1 col-form-label">Packaging GST(%)</label>
                        <div class="col-md-11 offset-md-1">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-md-11 offset-md-1 col-form-label">Logistics GST (%)</label>
                        <div class="col-md-11 offset-md-1">
                            <input type="text" class="form-control" id="inputPassword" />
                        </div>
                    </div>
                </div>

            </div>
            <div className='row'>
                <h4 className='mt-4' style={{ fontSize: "20px" }}>Milestone</h4>
                <div className='col-4 mt-4 MilestonePNa'>
                    <p>Delivery Date</p>
                    <input type="text" class="form-control mb-3" />
                    <input type="text" class="form-control mb-3" />
                    <input type="text" class="form-control mb-3" />
                </div>
                <div className='col-4 mt-4'>
                    <p>Percentage</p>
                    <input type="text" class="form-control mb-3" />
                    <input type="text" class="form-control mb-3" />
                    <input type="text" class="form-control mb-3" />
                </div>
                <div className='col-4 mt-4'>
                    <p>Payment</p>
                    <input type="text" class="form-control mb-3" />
                    <input type="text" class="form-control mb-3" />
                    <input type="text" class="form-control mb-3" />
                </div>
                {/* <button className='btn theme-btn'>Add + </button> */}
                <div className='col-12'>
                    <button style={{ width: "14x0px" }} type="button" class="btn btn-primary">
                        Add More <span class="badge">+</span>
                    </button>
                </div>
                <div className='col-md-6'></div>
                <div className='col-md-3 col-6 mt-5  '>
                    {/* <button className='btn w-100 btn-outline-primary'  data-bs-dismiss="modal">Cancel</button> */}
                    <Link className='btn w-100 btn-outline-primary' to='/request-for-quote/faqResponse'>Cancel</Link>
                </div>
                <div className='col-md-3 col-6 mt-5'>
                    <button className='btn w-100 btn-primary'>Save</button>

                </div>
            </div>
        </>
    )
}

export default ResponseRFQ
