import React, { useState } from 'react';
import './filter.css';

import { RiSortDesc } from 'react-icons/ri';
import { AiOutlineClose } from 'react-icons/ai';
import "jquery-ui-dist/jquery-ui";
import Form from 'react-bootstrap/Form';

import '../mobilenav/mobilenav.css';

const Sort = () => {


    // const [sort, setSort] = useState(false);


    const [show, setShow] = useState(false);


    return (


        <>
            <div className='sort'>
                <span> <RiSortDesc  onClick={() => setShow(!show)} /> Sort</span>
            </div>
            {
                show ?
                    <div className='sort-profile'>
                        <div className='prf-sort'>
                        <span className='sort'><RiSortDesc /> Sort</span>
                            <AiOutlineClose onClick={() => setShow(!show)} />
                        </div>
                        <hr />
                        <ul>
                            <li className='sort1'>Popularity
                                <Form.Check type="radio" aria-label="radio 1" />
                            </li>
                            <li className='sort2'>Price Low To High
                                <Form.Check type="radio" aria-label="radio 1" />
                            </li>
                            <li className='sort3'>Price High To Low
                                <Form.Check type="radio" aria-label="radio 1" />
                            </li>
                            <li className='sort4'>Newest First
                                <Form.Check type="radio" aria-label="radio 1" />
                            </li>
                        </ul>
                    </div> : null
            }

        </>


        // <>

        //     <div className='filter-popup'>
        //         <div className='filter' onClick={() => setFilter(!filter)}>
        //             <BiFilterAlt className='filter' /> Filter
        //         </div>
        //         {
        //             filter ?
        //                 <div className='popupshow'>
        //                     <AiIcons.AiOutlineClose onClick={() => setFilter(!filter)} />
        //                     <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        //                         <Row>
        //                             <Col sm={3} xs={6}>
        //                                 <Nav variant="pills" className="flex-column">
        //                                     <h3>Filters</h3>
        //                                     <Nav.Item>
        //                                         <Nav.Link eventKey="first">Order ID</Nav.Link>
        //                                     </Nav.Item>
        //                                     <Nav.Item>
        //                                         <Nav.Link eventKey="second">Payment Mode</Nav.Link>
        //                                     </Nav.Item>
        //                                     <Nav.Item>
        //                                         <Nav.Link eventKey="third">RFQ</Nav.Link>
        //                                     </Nav.Item>
        //                                     <Nav.Item>
        //                                         <Nav.Link eventKey="fourth">Status</Nav.Link>
        //                                     </Nav.Item>
        //                                     <Nav.Item>
        //                                         <Nav.Link eventKey="fifth">Date Created</Nav.Link>
        //                                     </Nav.Item>

        //                                 </Nav>
        //                             </Col>
        //                             <Col sm={9} xs={6}>
        //                                 <Tab.Content>
        //                                     <Tab.Pane eventKey="first">
        //                                         <p>Order Id Details</p>
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="second">
        //                                         <p>Payment Mode Details</p>
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="third">
        //                                         <p>RFQ</p>
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="fourth">
        //                                         <Form.Check type="checkbox" label="Pending" />
        //                                         <Form.Check type="checkbox" label="Delivered" />
        //                                         <Form.Check type="checkbox" label="Active" />
        //                                         <Form.Check type="checkbox" label="Deleted" />
        //                                         <Form.Check type="checkbox" label="Not Active" />
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="fifth">
        //                                         <p>Date Created</p>
        //                                     </Tab.Pane>
        //                                 </Tab.Content>
        //                             </Col>
        //                         </Row>
        //                     </Tab.Container>
        //                     <div className='filterBtns'>
        //                         <button className='cancel'>Cancel</button>
        //                         <button className='apply'>Apply</button>
        //                     </div>


        //                 </div> : null
        //         }



        //     </div>



        // </>


    )
}

export default Sort