const OrderCustomerData = [{
    id: 1,
    total: 200,
    name:"Orders",
    imgsrc:'./images/TotalOrder.png'
    
},
{
    id: 2,
    total: 2140,
    name:"Total Customers",
    imgsrc:'./images/Customers.png'
}
]

export default OrderCustomerData;