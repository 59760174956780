import React, { useContext } from "react";
import "jquery-ui-dist/jquery-ui";
import $ from "jquery";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import DynamicGreeting from "../component/Greeting"

const UserProfile = (props) => {
  const user = JSON.parse(localStorage.getItem("auth_user"));

  const { auth, setAuth } = useContext(AuthContext);

  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear("token_web");
    localStorage.clear("auth_user");
    navigate("/login");
    setAuth(false);
  };

  useEffect(() => {
    // $('#respondId').click(function(){
    //     $('.modal-backdrop').remove();
    //     $('#mainhBody').removeClass('modal-open');
    // })
    $(".InputIdSearchBoxInput").focusin(function () {
      $(".searchproductCatePop").slideDown();
    });
    $(".InputIdSearchBoxInput").focusout(function () {
      $(".searchproductCatePop").slideUp();
    });
    $(".sort").click(function () {
      $(".sort-profile").show();
    });
    $("#notification_id").click(function () {
      $(".notification-popup").slideDown();
      $(".userProfile-popup").slideUp();
      $(".NotiPro_Outer").show();
    });
    $("#prifile_id").click(function () {
      $(".userProfile-popup").slideDown();
      $(".notification-popup").slideUp();
      $(".NotiPro_Outer").show();
    });
    $(".NotiPro_Outer").click(function () {
      $(".notification-popup").slideUp();
      $(".userProfile-popup").slideUp();
      $(".NotiPro_Outer").hide();
    });

    $("#toogleIcon").click(function () {
      $("#SideMenuHS").addClass("showsiemenu12");
      $("#closeIconMenu").show();
    });

    $("#Catalougetoggle").click(function () {
      // $('#Catalouge').slideDown("slow");
      $("#Catalouge").slideToggle("slow");
      $("#Catalouge").className("active");
    });

    $(
      "#Dashboardtoggle,#Orderstoggle,#AwaitingResponsetoggle,#Paymentstoggle,#Loantoggle,#Supporttoggle"
    ).click(function () {
      $("#Catalouge").slideUp("slow");
    });

    $("#closeIconMenu").click(function () {
      $("#SideMenuHS").removeClass("showsiemenu12");
    });

    $(".addUseIcon").mouseenter(function () {
      $(".addSubAccount_popUp").show();
    });
    $(".addUseIcon").mouseleave(function () {
      $(".addSubAccount_popUp").hide();
    });

    // $("#switchtoseller").click(function () {
    //   {
    //     if (document.getElementById("switchtoseller").checked == true)
    //       // window.location.href = "http://localhost:3000/busers";
    //   }
    // });

    if (!localStorage.getItem("token_web") || !user) {
      logout();
    }
  }, []);

  return (
    <>
      <div className="formobile">
        <h3 className="center">{user.name}</h3>
      </div>

      <div className="d-flex justify-content-between align-items-center userProf">
        <div className="userName">
          <p className='text-capitalize'>Hi {user.name}</p>
          <DynamicGreeting />
        </div>
        <div className="userProfileAction">
          <div className="UserTreeActn">
            <span
              id="prifile_id"
              className="UserNameF d-flex justify-content-center align-items-center"
            >
              {user.name?.[0].toUpperCase()}
            </span>
          </div>
          <div className="NotiPro_Outer">

            <div className="userProfile-popup">
              <div className="userPr78">
                <Link className="w-100" to="/user">
                  Profile
                </Link>
                <button onClick={logout} className="w-100">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
