import React, { useState } from 'react';
import './filter.css';

import { BiFilterAlt } from 'react-icons/bi';
import "jquery-ui-dist/jquery-ui";
import Form from 'react-bootstrap/Form';

import '../mobilenav/mobilenav.css';
import { IconContext } from 'react-icons';
import * as AiIcons from 'react-icons/ai';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Sort from './Sort';

const Filter = () => {

    const [filter, setFilter] = useState(false);
    // const [sort, setSort] = useState(false);

    return (

        <>
            <div className='filter-mob'>
                <div className='table-filter'>

                    <Sort />
                    <div className='filter-popup'>
                        <div className='filter' onClick={() => setFilter(!filter)}>
                            <BiFilterAlt className='filter' /> Filter
                        </div>
                        {
                            filter ?
                                <div  className='popupshow '>
                                    <AiIcons.AiOutlineClose onClick={() => setFilter(!filter)} />
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Row>
                                            <Col sm={3} xs={6} style={{borderRight:"1px solid #e6e6e6"}}>
                                                <Nav variant="pills" className="flex-column">
                                                    <h3>Filters</h3>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first">Order ID</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second">Payment Mode</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third">RFQ</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="fourth">Status</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="fifth">Date Created</Nav.Link>
                                                    </Nav.Item>

                                                </Nav>
                                            </Col>
                                            <Col sm={9} xs={6}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <p>Order Id Details</p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <p>Payment Mode Details</p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">
                                                        <p>RFQ</p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fourth">
                                                        <Form.Check type="checkbox" label="Pending" />
                                                        <Form.Check type="checkbox" label="Delivered" />
                                                        <Form.Check type="checkbox" label="Active" />
                                                        <Form.Check type="checkbox" label="Deleted" />
                                                        <Form.Check type="checkbox" label="Not Active" />
                                                    </Tab.Pane>
                                                    <Tab.Pane className="custom-cal" eventKey="fifth">
                                                        <p>Date Created</p>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                    <div className='filterBtns'>
                                        <button className='cancel'>Cancel</button>
                                        <button className='apply'>Apply</button>
                                    </div>


                                </div> : null
                        }



                    </div>
                  
                </div>
            </div>
        </>

    )
}

export default Filter