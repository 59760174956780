import React from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
const RecentOrderTable = ({ recentorder }) => {
    return (
        <>
            <div className='recntorder-outer'>
                <div className='d-flex justify-content-between mb-3'>
                    <h5>Recent Orders</h5>
                    {/* <button className='btn btn-danger'>Order Id</button> */}
                </div>
                <div className="table table-responsive table-borderless">
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th className='text-nowrap'>Invoice No.</th>
                                <th className='text-nowrap'>Payment Mode</th>
                                <th className='text-nowrap'>Date Created</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentorder.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.order_id} </td>
                                        <td className='text-nowrap'>{item.payment_mode}</td>
                                        <td className='text-nowrap'><Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment></td>
                                        <td className="text-center">
                                            <Link
                                                className="btn theme-bg text-white"
                                                to={"/orders/" + item.id}
                                            >
                                                {/* data-bs-toggle="modal" data-bs-target={"#idnum" + curElm.id} */}

                                                <span>View</span>
                                            </Link>{" "}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default RecentOrderTable