import React from 'react'

import {ResponsiveContainer, BarChart, Bar, XAxis, Tooltip, YAxis} from 'recharts'

const Chart = ({chartData}) => {
  
  
  
  // console.log(chartData)

  return (
    <>

    
      <ResponsiveContainer width="100%" aspect={3}>
            <BarChart data={chartData}>
            <XAxis  axisLine={false} dataKey="month"  />
            {/* interval="preserveStartEnd" */}
            <YAxis dataKey="sales"  axisLine={false} />
                <Bar type="monotone" dataKey="sales"  stroke="#042D5F" fill='#042D5F' strokeWidth={3}  barSize={19} radius={[3, 3, 0, 0]}></Bar>  
                <Tooltip  cursor={{fill: 'transparent'}} wrapperStyle={{ outline: "none" }} />          
            </BarChart>
        </ResponsiveContainer>
    </>
  )
}

export default Chart