const AllProductApi = [
    {
        id:1,
        imgSrc:"../images/products/img1.png",
        name: "Camlin Engineers Blue Paste 20 ml - 1",
        SKUID: 22504,
        VendorSKUID: "SKU086554655",
        status:"inactive",
        categrization: "Adhesives",
        Inventory:"Out of stock",
        Price: 1200,
        Gst:"18%",
        QtyPerPack:"2 Unit", 
    },
    {
        id:2,
        imgSrc:"../images/products/img1.png",
        name: "Camlin Engineers Blue Paste 20 ml - 2",
        SKUID: 22503,
        VendorSKUID: "SKU086554645",
        status:"inactive",
        categrization: "Adhesives",
        Inventory:"In stock",
        Price: 1300,
        Gst:"18%",
        QtyPerPack:"5 Unit", 
    },
    {
        id:3,
        imgSrc:"../images/products/img1.png",
        name: "Camlin Engineers Blue Paste 20 ml - 3",
        SKUID: 22604,
        VendorSKUID: "SKU086554699",
        status:"inactive",
        categrization: "Adhesives",
        Inventory:"Out of stock",
        Price: 1700,
        Gst:"18%",
        QtyPerPack:"9 Unit", 
    },
    {
        id:4,
        imgSrc:"../images/products/img1.png",
        name: "Camlin Engineers Blue Paste 20 ml - 4",
        SKUID: 22544,
        VendorSKUID: "SKU086554666",
        status:"inactive",
        categrization: "Adhesives",
        Inventory:"In stock",
        Price: 1700,
        Gst:"18%",
        QtyPerPack:"2 Unit", 
    },
]
export default AllProductApi