import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { baseUrl } from '../utils/baseUrl';
import { toasterUpdate } from '../utils/toasterUpdate';
const LatestRfq = () => {

    const [data, setData] = useState([]);

    const fetchApi = async () => {
        const token = localStorage.getItem("token_web");

        const response = await fetch(`${baseUrl}/rfq/seller?page=1`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        const result = await response.json();

        if (result.error) {
            toast.error(result.message);
            return;
        }
        setData(() => result.data.data);
        toast.dismiss();
    };

    useEffect(() => {
        fetchApi();
    }, [])

    return (
        <>

            <div className='latestRFQ-outer'>
                <div className='d-flex justify-content-between mb-3'>
                    <h5>Latest RFQ</h5>
                </div>
                <div className='table table-responsive table-borderless'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>RFQ ID</th>
                                <th>Details </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td><Link to={"/request-for-quote/faqResponse/" + item.id} className="DetailsView btn">View</Link></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default LatestRfq