import React from 'react'
import { Link } from 'react-router-dom'
import UserProfile from '../userProfile'

import './style.css'
// import './custom.js'
const UploadBulk = () => {
  return (
    <>
      <UserProfile />
      <p className='mt-5'><b>Instructions</b></p>
      <ol className='mb-5'>
        <li>First download the CSV file </li>
        <li>Then fill the excel file according to format</li>
        <li>Then Upload that file in down below </li>
      </ol>
      <p className='mb-0 mt-3'><b>Bulk Upload </b></p>
      <form class="form-container" enctype='multipart/form-data'>
        <div class="upload-files-container">
          <div class="drag-file-area">
            <div>
              <span class="material-icons-outlined upload-icon"> file_upload </span>
              <h3 class="dynamic-message">
                <label class="label"><span class=" d-block mt-3"> <input type="file" class="default-file-input d-none" /> <span class="browse-files-text">Upload</span> <span>CSV File Here </span> </span> </label>
              </h3>
              <button type="button" class="upload-button btn btn-primary"> Upload </button>
            </div>
          </div>
          <span class="cannot-upload-message"> <span class="material-icons-outlined">error</span> Please select a file first <span class="material-icons-outlined cancel-alert-button">cancel</span> </span>
          <div class="file-block">
            <div class="file-info"> <span class="material-icons-outlined file-icon">description</span> <span class="file-name"> </span> | <span class="file-size">  </span> </div>
            <span class="material-icons remove-file-icon">delete</span>
            <div class="progress-bar"> </div>
          </div>

        </div>
      </form>

      <Link to='/uploadBulk' className="upload-button btn btn-primary float-end my-3"> Add Products </Link>


    </>
  )
}

export default UploadBulk