import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";
import { Link } from "react-router-dom";
import Pagination from "../component/Pagination";

const AllProducts = ({ product }) => {
  const [data, setData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [id, setId] = useState([]);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/products?page=${currentPage}`, {
      headers: {
        Authorization: token,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    toast.dismiss(toastID);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  const deleteApi = async () => {
    const token = localStorage.getItem("token_web");

    if (id.length === 0) {
      toast.error("Select atleast one catalogue!!");
      return;
    }

    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/product/${id}/destroy`, {
      method: "delete",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setId([]);
    toasterUpdate(toastID, result.message, "success");
    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <>
      <div className="table-responsive mt-5 table-borderless">
        <table className="table rowGray">
          <thead>
            <tr>
              <th>ID</th>
              <th>SKU</th>
              <th>Name</th>
              <th>HSN Code</th>
              <th>CAS No.</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td style={{ padding: "10px" }}>{item.id}</td>
                    <td style={{ padding: "10px" }}>{item.sku}</td>
                    <td style={{ padding: "10px" }}>{item.name}</td>
                    <td style={{ padding: "10px" }}>{item.catalog?.hsn}</td>
                    <td style={{ padding: "10px" }}>{item.catalog?.cas_no}</td>
                    <td style={{ padding: "10px" }}>
                      {item.stock ? "in stock" : "stock out"}
                    </td>
                    <td style={{ padding: "10px" }}>
                      <Link to={`/product/${item.id}/edit`}>Edit</Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Pagination
        handlePageChange={handlePageChange}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default AllProducts;
