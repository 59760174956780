import React, { useState } from "react";
import MenuItems from "./menuData";
import AllMenu from "./allMenu";
import { Link } from "react-router-dom";

const Navbar = () => {
  const shoot = () => {
    window.location.href = "https://rasayankart.in/#/orders";
  };

  const [menuItem] = useState(MenuItems);
  return (
    <>
      <div className="mainNavbar">
        <div className="logo">
          <div>
            <a href="https://rasayankart.in/">
              {" "}
              <img src="/images/logo.png" alt="hh" />
            </a>
          </div>
        </div>
        <ul className="menuItems list-unstyled">
          <AllMenu menuItem={menuItem} />
        </ul>
        <div className="vinn">
          <div
            onClick={shoot}
            className="form-check form-switch ps-0 d-flex align-items-center"
          >
            <label
              className="form-check-label float-start"
              htmlFor="switchtoseller"
              style={{ color: "#fff" }}
            >
              Seller
            </label>
            <input
              className="form-check-input  float-start"
              style={{ marginLeft: "10px" }}
              type="checkbox"
              role="switch"
              id="switchtoseller"
            />
            <label
              className="form-check-label float-start"
              htmlFor="switchtoseller"
              style={{ color: "#fff" }}
            >
              Buyer
            </label>
          </div>
          {/* <img id="closeIconMenu" src='../images/close-menu-icon.png' alt="toggleMenuIcon" /> */}
        </div>
      </div>
    </>
  );
};

export default Navbar;
