import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserProfile from "../userProfile";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";
import { BsPencil, BsTrash } from "react-icons/bs";
import "./style.css";
const Address = () => {
  const [data, setData] = useState([]);

  const [address_id, setAddress_id] = useState("");
  const [address, setaddress] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [zip, setzip] = useState("");

  const closeRef = useRef(null);
  const editCloseRef = useRef(null);
  const formRef = useRef(null);
  const editFormRef = useRef(null);

  function closeModal() {
    closeRef.current.click();
    editCloseRef.current.click();
  }

  const fetchApi = async (e) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/addresses`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    if (!result.data.complete) {
      toast.dismiss(toastID);
    }

    setData((prev) => result.data);
    toast.dismiss(toastID);
  };

  const createApi = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/address`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ address, state, city, country, zip }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");
    closeModal();
    fetchApi();
  };

  const editApi = async (e, id = "") => {
    e.preventDefault();
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/address/${address_id}/edit`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ address, state, city, country, zip }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");
    closeModal();
    fetchApi();
  };

  const deleteApi = async (id) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/address/${id}/delete`, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");
    fetchApi();
  };

  const loadAddress = (item) => {
    setzip(item.zip);
    setcity(item.city);
    setcountry(item.country);
    setstate(item.state);
    setaddress(item.address);
    setAddress_id(item.id);
  };

  const makeDefaultApi = async (id) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/address/${id}/default`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");

    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <UserProfile />
      <h4
        className="my-3"
        style={{
          fontWeight: "400",
          color: "#767676",
          fontFamily: "'Inter', sans-serif !important",
        }}
      >
        Manage Addresses
      </h4>

      <div className="row">
        {data &&
          data.map((item, index) => {
            return (
              <div className="col-md-4" key={index}>
                <div
                  className={`address-container border rounded-3 p-3 ${
                    item.is_default && "active"
                  }`}
                >
                  <div className="text-end">
                    <button
                      className="btn btn-primary rounded-circle mx-2"
                      data-bs-toggle="modal"
                      data-bs-target="#editAddressModal"
                      onClick={() => loadAddress(item)}
                    >
                      <BsPencil size={"12px"} />
                    </button>
                    <button
                      onClick={() => deleteApi(item.id)}
                      className="btn btn-danger rounded-circle"
                    >
                      <BsTrash size={"12px"} />
                    </button>
                  </div>
                  <address>{item.address}</address>
                  <address>{item.state}</address>
                  <address>{item.city}</address>
                  <address>{item.country}</address>
                  <address>{item.zip}</address>

                  <div className="text-end">
                    <button
                      onClick={() => makeDefaultApi(item.id)}
                      className={`default-btn`}
                    >
                      {item.is_default ? "Marked Default" : "Make Default"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="">
        <div className="">
          <button
            data-bs-toggle="modal"
            data-bs-target="#addressModal"
            className="add-address-btn"
          >
            <img src="/images/addSub.png" alt="addSub" />
          </button>

          {/* add address modal  */}
          <div
            className="modal fade"
            id="addressModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Add Address
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={createApi} ref={formRef}>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Address
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="address"
                          id="address"
                          onInput={(e) => setaddress(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        State
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="state"
                          id="state"
                          onInput={(e) => setstate(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        City
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          onInput={(e) => setcity(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Country
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="country"
                          id="country"
                          onInput={(e) => setcountry(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Zip
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="number"
                          name="zip"
                          id="zip"
                          onInput={(e) => setzip(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="float-end mt-2">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={closeRef}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary ms-1">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* edit address modal  */}
          <div
            className="modal fade"
            id="editAddressModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Edit Address
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={editApi} ref={editFormRef}>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Address
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="address"
                          id="address"
                          value={address}
                          onInput={(e) => setaddress(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        State
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="state"
                          id="state"
                          value={state}
                          onInput={(e) => setstate(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        City
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          value={city}
                          onInput={(e) => setcity(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Country
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="country"
                          id="country"
                          value={country}
                          onInput={(e) => setcountry(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Zip
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="number"
                          name="zip"
                          id="zip"
                          value={zip}
                          onInput={(e) => setzip(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="float-end mt-2">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={editCloseRef}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary ms-1">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
