import React from "react";
import formatter from "../utils/formatter";
import Moment from "react-moment";

const RecentTransactions = ({ data }) => {
  return (
    <>
      <div className="table recentTrans table-borderless table-responsive">
        <h3>Sanctioned Money Transactions</h3>
        <table className="table">
          <tr>
            <th>ID</th>
            <th>Amount Requested</th>
            <th>Amount Sanctioned</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
          {data.map((item) => {
            return (
              <>
                <tr>
                  <td>{item.id}</td>
                  <td>{formatter.format(item.amount)}</td>
                  <td>{formatter.format(item.sanctioned_amount || '---')}</td>
                  <td>{item.status}</td>
                  <td><Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment></td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default RecentTransactions;
