import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserProfile from "../userProfile";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";

const User = () => {
  const [data, setData] = useState(false);
  const user = JSON.parse(localStorage.getItem("auth_user"));
  const navigate = useNavigate();

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    if (!result.data.complete) {
      toast.dismiss(toastID);
    }

    setData((prev) => result.data.user);

    toast.dismiss(toastID);
  };

  useEffect(() => {
    syncProfile();
  }, []);

  return (
    <>
      <UserProfile />
      <h4
        className="my-3"
        style={{
          fontWeight: "400",
          color: "#767676",
          fontFamily: "'Inter', sans-serif !important",
        }}
      >
        Users
      </h4>

      {data && (
        <div className="UserEdit_outer">
          <div className="UserEdit_pic d-flex justify-content-center align-items-center">
            <img
              src={data.doc[0] ? data.doc[0].url : "/images/User.png"}
              alt="user"
              className="img-fluid"
            />
          </div>
          <p className="mb-0">{data.vendor?.company}</p>
          <p>Created : {new Date(data.created_at).toLocaleDateString()}</p>
          <Link to={"/profile"} className="btn btn-primary w-100">
            View Profile
          </Link>
        </div>
      )}

      
    </>
  );
};

export default User;
