const RecentOrderApi = [{
    id:1,
    rfqid: "NA",
    payment: "milestone",
    orderid: 12382,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:2,
    rfqid: 12342,
    payment: "milestone",
    orderid: 12221,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:3,
    rfqid: "NA",
    payment: "milestone",
    orderid: 87821,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:4,
    rfqid: "NA",
    payment: "milestone",
    orderid: 12671,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:5,
    rfqid: "NA",
    payment: "milestone",
    orderid: 13927,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:6,
    rfqid: "NA",
    payment: "milestone",
    orderid: 1871,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:7,
    rfqid: "NA",
    payment: "milestone",
    orderid: 7861,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:8,
    rfqid: "NA",
    payment: "milestone",
    orderid: 7621,
    customer:"viren",
    amount:1300,
    quantity:32,
},
{
    id:9,
    rfqid: "NA",
    payment: "milestone",
    orderid: 1434,
    customer:"Ajay",
    amount:970,
    quantity:32,
},
{
    id:10,
    rfqid: "NA",
    payment: "milestone",
    orderid: 88321,
    customer:"Anil",
    amount:1200,
    quantity:36,
}
]

export default RecentOrderApi;