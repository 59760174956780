import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toasterUpdate } from "../utils/toasterUpdate";
import { baseUrl } from "../utils/baseUrl";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [password, setPassword] = useState("");

  const { auth, setAuth } = useContext(AuthContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const loggedin = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/login/seller`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, result.message, "success");

    const token = localStorage.setItem("token_web", result.data.token);
    const user = localStorage.setItem(
      "auth_user",
      JSON.stringify(result.data.user)
    );

    setAuth(() => {
      return { user, token };
    });

    navigate("/");
  };

  const forgot = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/password/email`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: resetEmail }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, "Password reset link sent to your email", "success");
  };

  const checkLogin = () => {
    const token = localStorage.getItem("token_web");
    if (token) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <>
      <section className="welcome_page">
        <div className="box">
          <div className="box-display">
            <form onSubmit={loggedin} className="leftwel">
              <h1>Login </h1>
              <p>
                Complete your KYC before logging in to the seller dashboard.{" "}
                <a href={`https://rasayankart.in/`} target="_blank">
                  login to complete KYC
                </a>
              </p>

              <div className="form-group mb-4">
                <label className="form-label">Email ID Or Phone Number</label>
                <input
                  type="email"
                  value={email}
                  onInput={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="name"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label">Password</label>
                <input
                  type="Password"
                  name="Password"
                  value={password}
                  onInput={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="form-control"
                  required
                />
              </div>
              <div className="text-center">
                <Link
                  to={`#`}
                  onClick={handleShow}
                  className="mt-2 text-end d-block"
                >
                  Forgot Password
                </Link>

                <button className="account btn btn-primary" type="submit">
                  Login
                </button>

                <Link to={`/register`} className="mt-3 text-center d-block">
                  Create an account ?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={forgot}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                required
                placeholder="name@example.com"
                onInput={(e) => setResetEmail(e.target.value)}
                autoFocus
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Send Reset Link
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
