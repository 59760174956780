import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

const AllRequest = ({request, from}) => {
  return (
    <>
        <div className='row mt-4'>
          <div className='col-12'>
                <div className='table table-responsive table-borderless'>
                    <table className='table rowGray'>
                        <thead>
                        <tr>
                            <th className='text-nowrap'>Sr. No.</th>
                            <th className='text-nowrap'>RFQ ID</th>
                            <th className='text-nowrap'>RFQ Name</th>
                            <th className='text-nowrap'>RFQ Expires</th>
                            <th className='text-nowrap'>Date Created</th>
                            <th className='text-nowrap'>Status</th>
                            <th className='text-nowrap'>Order Type</th>
                            <th>Details</th>
                        </tr></thead>
                        <tbody>
                        {request.map((item, index)=>{
                            return(
                                <tr className='py-4' key={index}>
                                    <td>{from + index} </td>
                                    <td>{item?.rfq?.rfq_id}</td>
                                    <td  className='text-nowrap'>{item?.rfq?.label}</td>
                                    <td  className='text-nowrap text-capitalize'><Moment fromNow>{item?.rfq?.opentill}</Moment></td>
                                    <td className='text-nowrap'><Moment format="D-MMMM-Y">{item.rfq?.created_at}</Moment></td>
                                    <td className={`text-capitalize px-0 ${(item.status == 'ongoing' || item.status == 'accept') ? 'text-success' : 'text-danger'}`}>{item.status}</td>
                                    <td className='text-capitalize'>{item?.rfq?.payment_type}</td>
                                    <td className="text-center">
                                        <Link className="btn theme-bg text-white " to={"/request-for-quote/faqResponse/" + item.id}  >               
                                            <span>View</span>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
          </div>
        </div>
    </>
  )
}

export default AllRequest