import React from 'react'
import UserProfile from '../userProfile'

const CreateBrand = () => {
  return (
    <>
        <UserProfile />
        <h1 className='mt-4 theme-color' style={{fontSize:"24px"}}>Create Brands</h1>
        <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-general-tab" data-bs-toggle="pill" data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general" aria-selected="true">General</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-Image-tab" data-bs-toggle="pill" data-bs-target="#pills-Image" type="button" role="tab" aria-controls="pills-Image" aria-selected="false">Image</button>
            </li>
            </ul>
            <hr className='mt-0' />
            <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
                <div className='row mt-5'>
                    <div className='col-7'>
                        <div class="mb-3 row">
                            <label for="inputId" class="col-sm-2 col-form-label">id</label>
                            <div class="col-sm-10">
                            <input type="text" class="form-control bg-light" id="cId" />
                            </div>
                        </div>

                        <div class="mb-3 row">
                            <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control bg-light" id="Name" />
                            </div>
                        </div>
                        
                    </div>

                    
                </div>
            </div>
            <div class="tab-pane fade" id="pills-Image" role="tabpanel" aria-labelledby="pills-Image-tab">Image</div>
            
            </div>

            <div className='row mt-5'>
                <div className='col-12'>
                    <button style={{width:"130px"}}  className='btn theme-btn'>Save</button>
                    <button style={{width:"130px"}} className='btn  ms-3 theme-btn'>Delete</button>
                    
                </div>
            </div>
    </>
  )
}

export default CreateBrand