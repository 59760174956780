import React, { useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
const SuportFilter = () => {
    const [value, onChange] = useState(new Date());
  return (
    <>
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-content-transparent">

            <div className="modal-body orderPop">
              <div  className="d-flex align-items-start new-c-div">
                <div className="nav tablCusNav w-50 flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ paddingLeft: "15px" }}>
                  <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" style={{ textAlign: "left" }}>Ticket Id</button>
                  <button className="nav-link  text-start" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Company Name</button>
                  {/* <button className="nav-link  text-start" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Payment mode</button>
                  <button className="nav-link  text-start" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Status</button> */}
                  <button className="nav-link  text-start" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-new" type="button" role="tab" aria-controls="v-pills-new" aria-selected="false">Date</button>
                </div>
                <div className="tab-content w-50 allBtnShowtab" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <div className='albtnsInTabs'>

                      <input type="text" className="form-control" />

                      
                      <div className='d-flex justify-content-between mt-4'>
                        <button className='btn'>Cancel</button>
                        <button className='btn btn-primary text-white'>Apply Filter</button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <input type="text" className="form-control" />
                        <div className='d-flex justify-content-between mt-4'>
                          <button className='btn'>Cancel</button>
                          <button className='btn btn-primary text-white'>Apply Filter</button>
                        </div>
                  </div>
                  {/* <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                    <div className='col-12'>
                    <input type="radio" className="btn-check" name="options" id="option2" autocomplete="off" />
                    <label className="btn btn-light w-100 mb-2  text-start" htmlFor="option2">Milestone</label>
                    </div>
                    <div className='col-12'>
                    <input type="radio" className="btn-check" name="options" id="option1" autocomplete="off" />
                    <label className="btn btn-light w-100 text-start" htmlFor="option1">Date Based</label>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <button className="btn">Cancel</button>
                      <button className="btn btn-primary text-white">Apply Filter</button>
                    </div>
                  </div> */}
                  {/* <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                  
                  <div className='albtnsInTabs'>

                    

                    <div className='TabsBTNSC w-100'>
                      <input type="checkbox" className="btn-check" id="btn-check3" autocomplete="off" />
                      <label className="btn  text-start btn-primary btn-light w-100" htmlFor="btn-check3">Delivered</label>
                    </div>
                    <div className='TabsBTNSC w-100'>
                      <input type="checkbox" className="btn-check" id="btn-check2" autocomplete="off" />
                      <label className="btn text-start btn-primary btn-light w-100" htmlFor="btn-check2">Not Delivered</label>
                    </div>
                    <div className='TabsBTNSC w-100'>
                      <input type="checkbox" className="btn-check" id="btn-check4" autocomplete="off" />
                      <label className="btn  text-start btn-primary btn-light w-100" htmlFor="btn-check4">Active</label>
                    </div>

                    <div className='TabsBTNSC w-100'>
                      <input type="checkbox" className="btn-check" id="btn-check5" autocomplete="off" />
                      <label className="btn  text-start btn-primary btn-light w-100" htmlFor="btn-check5">Pending</label>
                    </div>

                    
                    <div className='d-flex justify-content-between mt-4'>
                      <button className='btn'>Cancel</button>
                      <button className='btn btn-primary text-white'>Apply Filter</button>
                    </div>
                    </div>
                  </div> */}
                  <div className="tab-pane fade" id="v-pills-new" role="tabpanel" aria-labelledby="v-pills-new-tab">
                  <div className='albtnsInTabs'>
                        <Calendar onChange={onChange} value={value} />
                      </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default SuportFilter