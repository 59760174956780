import React, { useEffect, useRef, useState } from "react";
import UserProfile from "../userProfile";
import { Link, useParams } from "react-router-dom";
import { BiUpload } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";
import { BsFilePdf } from "react-icons/bs";
import Moment from "react-moment";

const OrderDetail = () => {
  const [data, setData] = useState({});
  const [attach, setAttach] = useState("");

  const logRef = useRef(null);
  const attachRef = useRef();

  const [orderStatus, setOrderStatus] = useState("order accepted");

  const [status, setStatus] = useState({
    order: false,
    fabrication: false,
    transit: false,
    delivered: false,
    qc: false,
  });

  const { id } = useParams();

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/seller/order/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setData(() => result.data);

    setStatus((prev) => {
      switch (result.data.order_status) {
        case "order accepted":
          return { ...prev, order: true };
          break;
        case "in fabrication":
          return { ...prev, order: true, fabrication: true };
          break;
        case "in transit":
          return { ...prev, order: true, fabrication: true, transit: true };
          break;

        case "delivered":
          return {
            ...prev,
            order: true,
            fabrication: true,
            transit: true,
            delivered: true,
          };
          break;

        case "qc accepted":
          return {
            ...prev,
            order: true,
            fabrication: true,
            transit: true,
            delivered: true,
            qc: true,
          };
          break;
        default:
          return { ...prev };
          break;
      }
    });
  };

  const attachApi = async () => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");

    const formData = new FormData();
    formData.append("order_id", id);
    formData.append("role", "buyer");
    formData.append("file", attach);

    const response = await fetch(`${baseUrl}/order/attachment`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    fetchApi();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/webp") ||
      (file && file.type === "image/jpg") ||
      (file && file.type === "application/pdf")
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 400000) {
          setAttach("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 400kb."
          );
        } else {
          setAttach(file);
        }
      };
    } else {
      setAttach("");
      attachRef.current.value = null;
      toast.error("Please select a valid image or pdf format.");
    }
  };

  const logApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Updating...");
    const response = await fetch(`${baseUrl}/order/${id}/status`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_status: orderStatus,
        log: logRef.current.value,
      }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    logRef.current.value = "";
    toasterUpdate(toastID, result.message, "success");

    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <>
      <UserProfile />
      <div className="rfq-page oder-detail profile_page">
        <div className="delivery-status-box">
          <strong className="bhd-sub-heading">Delivery Status</strong>
          <div className="delivery-status">
            <div className="ds-box">
              <div
                className="ds-box-img"
                style={{
                  background: status.order && "#0E9E01",
                }}
              >
                <img src={"/images/Vector.png"} alt="" />
              </div>
              <div>
                <div className="ds-heading">Order Accepted</div>
                {/*<div className="ds-date">28/10/2022</div>*/}
              </div>
            </div>
            <div
              className="connector acive-connector"
              style={{
                background: status.order && "#0E9E01",
              }}
            ></div>
            <div className="ds-box">
              <div
                className="ds-box-img"
                style={{
                  background: status.fabrication && "#0E9E01",
                }}
              >
                <img src={"/images/Vector1.png"} alt="" />
              </div>
              <div>
                <div className="ds-heading">In Production</div>
                {/*<div className="ds-date">28/10/2022</div>*/}
              </div>
            </div>
            <div
              className="connector"
              style={{
                background: status.fabrication && "#0E9E01",
              }}
            ></div>
            <div className="ds-box">
              <div
                className="ds-box-img"
                style={{
                  background: status.transit && "#0E9E01",
                }}
              >
                <img src={"/images/Vector2.png"} alt="" />
              </div>
              <div>
                <div className="ds-heading">In Transit</div>
                <div className="ds-date">-</div>
              </div>
            </div>
            <div
              className="connector"
              style={{
                background: status.transit && "#0E9E01",
              }}
            ></div>
            <div className="ds-box">
              <div
                className="ds-box-img"
                style={{
                  background: status.delivered && "#0E9E01",
                }}
              >
                <img src={"/images/Vector3.png"} alt="" />
              </div>
              <div>
                <div className="ds-heading">Deliverd</div>
                <div className="ds-date">-</div>
              </div>
            </div>
            <div
              className="connector"
              style={{
                background: status.delivered && "#0E9E01",
              }}
            ></div>
            <div className="ds-box">
              <div
                className="ds-box-img"
                style={{
                  background: status.qc && "#0E9E01",
                }}
              >
                <img src={"/images/qc.png"} alt="" />
              </div>
              <div>
                <div className="ds-heading">Qc Accepted</div>
                <div className="ds-date">-</div>
              </div>
            </div>
          </div>
          <div className="log-box">
            <strong>Logs</strong>

            <table style={{ width: "100%" }}>
              {data.logs &&
                data.logs.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start text-capitalize">
                        <span style={{ fontSize: "15px" }}>{item.log}</span>
                      </td>
                      <td className="text-start text-capitalize">
                      <Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
          <div className="col-12 text-end">
            <button
              className="btn btn-primary btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Update Status
            </button>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ border: "none", paddingBottom: "0px" }}
                >
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Status Update
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Status
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => setOrderStatus(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option
                        selected={data.order_status == "order accepted"}
                        value={"order accepted"}
                      >
                        Order Accepted
                      </option>
                      <option
                        selected={data.order_status == "in fabrication"}
                        value="in fabrication"
                      >
                        In Production
                      </option>
                      <option
                        selected={data.order_status == "in transit"}
                        value="in transit"
                      >
                        In Transit
                      </option>
                      <option
                        selected={data.order_status == "delivered"}
                        value="delivered"
                      >
                        Delivered
                      </option>
                      {/* <option
                        selected={data.order_status == "qc accepted"}
                        value="qc accepted"
                      >
                        Qc Accepted
                      </option> */}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Logs
                    </label>
                    <textarea
                      ref={logRef}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
                <div
                  className="modal-footer"
                  style={{ border: "none", paddingTop: "0px" }}
                >
                  <button
                    type="button"
                    onClick={logApi}
                    className="btn btn-primary theme-btn"
                  >
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <strong className="bhd-sub-heading">Payment Details </strong>
        <div>
          <div className="account">
            <div className="detail">
              <label>Order Type</label> <span className="text-capitalize">{data?.order_type}</span>
              {/*<Link>View</Link>*/}
            </div>
          </div>
          <div className="account">
            <div className="detail">
              <label>Product Price Type</label>{" "}
              <span className="text-capitalize">
                {data.delivery_type == "pickup"
                  ? "Ex-Factory cost"
                  : "Landed cost"}
              </span>
            </div>
          </div>
          <div className="account">
            <div className="detail">
              <label> Payment Mode</label>{" "}
              <span className="text-capitalize">
                {" "}
                {data.payment_method != "milestone"
                  ? "Spot Payment"
                  : "Milestone"}
              </span>
            </div>
          </div>
          <div className="account">
            <div className="detail">
              <label>Payment Details</label> <span className="text-capitalize">{data.payment_mode}</span>
            </div>

            {data.payment_mode && (
              <div className="detail">
                <label>Payment ID</label>
                <span>{data?.payment_reference}</span>
              </div>
            )}
          </div>
        </div>
        <strong className="bhd-sub-heading ">Account Details </strong>
        <div className="account">
          <div className="detail">
            <label> Order ID </label> <span className="text-capitalize">{data?.order_id}</span>
          </div>
          <div className="detail">
            <label> Shipping Details </label>{" "}
            <span className="text-capitalize">
              {data?.delivery_type == "pickup"
                ? "Ex-Factory cost"
                : "Landed cost"}
            </span>
          </div>
        </div>
        <div className="">
          <div className="account">
            <div className="detail">
              <label> Company Name</label>{" "}
              <span className="text-capitalize">{data.seller?.vendor.company}</span>
            </div>
            <div className="detail">
              <label> Payment Method </label> <span className="text-capitalize">{data.payment_mode}</span>
            </div>
          </div>
          <div className="account">
            <div className="detail">
              <label> Order Date </label> <span><Moment format="D-MMMM-Y hh:mm a">{data.created_at}</Moment></span>
            </div>
            <div className="detail">
              <label> Delivery (ETA) </label> <span>null</span>
            </div>
          </div>
        </div>
        <strong className="bhd-sub-heading">Address Details </strong>

        <div className="address">
          <div className="shipp">
            Shipping Address <br /> <br />{" "}
            <span>
              {data.shipping_address} {data.shipping_city},{" "}
              {data.shipping_state}, {data.shipping_country},{" "}
              {data.shipping_zip}
            </span>
          </div>
          <div className="shipp">
            Billing Address <br /> <br />{" "}
            <span>
              {data.shipping_address} {data.billing_city}, {data.billing_state},{" "}
              {data.billing_country}, {data.billing_zip}
            </span>
          </div>

          {data.delivery_type == "pickup" && (
            <div className="shipp">
              Pickup Address <br /> <br />{" "}
              <span>
                {data.deliver_from_address} {data.deliver_from_city},{" "}
                {data.deliver_from_state}, {data.deliver_from_country},{" "}
                {data.deliver_from_zip}
              </span>
            </div>
          )}
        </div>

        <div className="bhdBorder">
          <strong className="bhd-sub-heading">Items Ordered</strong>
          <div className="bhd-orders">
            <table className="seller-price-table bhd-table">
              <tr className="tablerow-head">
                <th className="table-head">Product</th>
                <th className="table-head">Unit Price</th>
                <th className="table-head">Quantity</th>
                <th className="table-head">Sub Total</th>
              </tr>
              <tr className="tablerow-data">
                <td className="table-data text-capitalize">{data.product?.name}</td>
                <td className="table-data">₹{data.price}</td>
                <td className="table-data">{data.quantity}</td>
                <td className="table-data">₹{data.sub_total}</td>
              </tr>
            </table>
          </div>

          <div className="bhd-total">
            <div className="total-div">
              <div className="total-div-left">Discount</div>
              <div className="total-div-right">₹{data.discount}</div>
            </div>
            <div className="total-div">
              <div className="total-div-left">Landed Cost</div>
              <div className="total-div-right">₹{data.shipping_charge}</div>
            </div>
            <div className="total-div">
              <div className="total-div-left">GST</div>
              <div className="total-div-right">₹{Math.abs(data.total - data.sub_total)}</div>
            </div>
            <div className="total-div">
              <div className="total-div-left">Total</div>
              <div className="total-div-right">₹{data.total}</div>
            </div>
          </div>
        </div>

        {data?.attachment?.length > 0 && (
          <div className="mt-4">
            <h5>Attachments</h5>
            <div className="d-flex">
              {data?.attachment?.map((el, index) => {
                return (
                  <div className="p-4">
                    <a
                      href={el.url}
                      className="border p-4"
                      target="_blank"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <BsFilePdf fontSize="medium" />
                      Download
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="docum mb-4">
          <label className="form-label">Documents</label>
          <input type="file" className="form-control" name="url" onChange={handleFileChange} />
        </div>
        <div
          style={{ margin: "0 0 auto auto", width: "50%", textAlign: "right" }}
        >
          <button className="continue" onClick={attachApi}>
            Send
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
