import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toasterUpdate } from "../utils/toasterUpdate";
import { baseUrl } from "../utils/baseUrl";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./login.css";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [resetEmail, setResetEmail] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/register`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ name, email, phone, password }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, result.message, "success");

    navigate("/login");
  };

  const forgot = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/password/email`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: resetEmail }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, "Password reset link sent to your email", "success");
  };

  const checkLogin = () => {
    const token = localStorage.getItem("token_web");
    if (token) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <>
      <section className="welcome_page">
        <div className="box">
          <div className="box-display">
            <form onSubmit={register} className="leftwel">
              <h1>Register </h1>
              <p>
                Complete your KYC before logging in to the seller dashboard.{" "}
                <a href={`https://rasayankart.in/`} target="_blank">
                  login to complete KYC
                </a>
              </p>
              <div className="form-group mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  value={name}
                  onInput={(e) => {
                    setName(e.target.value);
                  }}
                  name="name"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  value={email}
                  onInput={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="number"
                  value={phone}
                  onInput={(e) => {
                    setPhone(e.target.value);
                  }}
                  name="phone"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onInput={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="form-control"
                  required
                />
              </div>
              <div className="text-center">
                <Link
                  to={`#`}
                  onClick={handleShow}
                  className="mt-2 text-end d-block"
                >
                  Forgot Password
                </Link>

                <button className="account btn btn-primary" type="submit">
                  Register
                </button>

                <Link to={`/login`} className="mt-3 text-center d-block">
                  Already have an account ?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={forgot}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                required
                placeholder="name@example.com"
                onInput={(e) => setResetEmail(e.target.value)}
                autoFocus
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Send Reset Link
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
