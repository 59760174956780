import React, { useEffect, useState } from "react";
import UserProfile from "../userProfile/index";
import AllOrder from "./allOrder";
import Filter from "../filter/Filter";

import Filters from "../filters/index";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";
import Pagination from "../component/Pagination";

const Orders = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Fetching...");
    const response = await fetch(
      `${baseUrl}/seller/orders?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    setFrom(result.data.from);
    toast.dismiss(toastID);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <>
      <UserProfile title="Orders" />

      <div className="row mt-4">
        <div className="col-12">
          <div className="input-group mb-3 topSerrch21">
            <input
              type="text"
              className="form-control"
              id="inputID"
              placeholder=""
              aria-label="invoiceNum"
              aria-describedby="basic-addon1"
            />
            <span className="input-group-text" id="basic-addon1">
              <img src="/images/search.png" alt="search" height={18} />
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {/* <button
            className="btn theme-bg text-white btnFilterhideMob"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Filter
          </button> */}
          {/* <button className="btn btn-light ms-1" data-bs-toggle="modal" data-bs-target="#exampleModal">+</button> */}
        </div>
      </div>
      <Filters />
      <Filter />
      <AllOrder from={from} order={data} />
      <Pagination
        handlePageChange={handlePageChange}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default Orders;
