import React, { useEffect, useState } from "react";
import "./style.css";
import UserProfile from "../userProfile/index";
import Chart from "./chart";
import OrderCustomersCard from "./orderCustomersCard";

import OrderCustomerData from "./orderCustomersCardData";
import RecentOrderApi from "./recentOrderApi";
import RecentOrderTable from "./recentOrderTable";
import LatestRfq from "./latestRfq";
import SalesData from "./salesData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toasterUpdate } from "../utils/toasterUpdate";
import { baseUrl } from "../utils/baseUrl";

const Dashboard = () => {
  const [orderCustomer] = useState(OrderCustomerData);
  const [recentorder] = useState(RecentOrderApi);
  const [chartData, setChartdata] = useState(SalesData);

  const [data, setData] = useState([])

  const navigate = useNavigate();

  const d = new Date();
  let month = d.getMonth();
  // const filterData = (selectData) => {
  //   const UpdatedData = SalesData.filter((curElem)=>{
  //     return curElem.id === selectData;
  //   });
  //   setChartdata(UpdatedData)
  // }

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Fetching...");
    const response = await fetch(
      `${baseUrl}/seller/orders?page=1`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    setData(() => result.data.data);
    toast.dismiss(toastID);
  };

  const token = localStorage.getItem("token_web");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    fetchApi();

    return () => toast.dismiss();
  }, []);
  return (
    <>
      <UserProfile />
      <div className="row my-3">
        <div className="col-md-8">
          <div className="float-end">
            {/* <select
              onChange={(e) => {
                console.log(e.target.value);
              }}
              className="ms-2"
            >
              <option value="12">This Year</option>
              <option value={month + 1}>This Month</option>
              <option value={month + 1 - 1}>Last Month</option>
              <option value={month + 1 - 3}>Last 3 Months</option>
              <option value={month + 1 - 6}>Last 6 Months</option>
            </select> */}
          </div>
        </div>
      </div>
      <div className="row dashTowDifColr">
        <div className="col-md-8 position-relative mb-2 dashTowDifColrNew56">
          <div
            className="position-absolute"
            style={{ paddingTop: "1rem", paddingLeft: "1.3rem" }}
          >
            <p className="mb-0">Sales</p>
            <h3>Rs. 140000</h3>
          </div>
          <div className="chartBgClr">
            <Chart chartData={chartData} />
          </div>
        </div>
        <OrderCustomersCard orderCustomer={orderCustomer} />
      </div>
      <div className="row mt-4">
        <div className="col-md-9">
          <RecentOrderTable recentorder={data} />
        </div>
        <div className="col-md-3">
          <LatestRfq />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
