import React, { useRef, useState } from "react";
import Filter from "../filter/Filter";
import UserProfile from "../userProfile/index";
import SupportTable from "./supportTable";
import SuportFilter from "../filters/SuportFilter";
import { useEffect } from "react";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../utils/toasterUpdate";
import Pagination from "../component/Pagination";

const Support = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [data, setData] = useState([]);

  const [subject, setSubject] = useState("");
  const [query, setQuery] = useState("");
  const [attach, setAttach] = useState("");

  const [search, setSearch] = useState("");

  const attachRef = useRef(null);
  const formRef = useRef(null);
  const closeRef = useRef(null);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/tickets?page=${currentPage}&id=${search}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    setData(() => result.data.data);
    setFrom(() => result.data.from);
    setLastPage(result.data.last_page);
  };

  const createApi = async (e) => {
    try {
      e.preventDefault();

      const toastID = toast.loading("Creating...");
      const formData = new FormData();

      formData.append("subject", subject);
      formData.append("query", query);
      formData.append("attach", attach);

      const token = localStorage.getItem("token_web");
      const response = await fetch(`${baseUrl}/ticket`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      formRef.current.reset();
      closeModal();
      toasterUpdate(toastID, result.message, "success");
      fetchApi()
    } catch (error) {
      toast.error("Something Technical Error");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpg") ||
      file.type === "image/png" ||
      file.type === "image/webp" ||
      file.type === "application/pdf"
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 1000000) {
          setAttach("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 1000kb."
          );
        } else {
          setAttach(file);
        }
      };
    } else {
      setAttach("");
      attachRef.current.value = null;
      toast.error("Please select a valid WebP image format or PDF.");
    }
  };

  function closeModal() {
    closeRef.current.click();
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchApi();
  }, [search,currentPage]);

  return (
    <>
      <UserProfile />
      <SuportFilter />
      <div className="row mt-4">
        <div className="col-12">
          <div className="input-group mb-3 topSerrch21">
            <input
              type="number"
              className="form-control"
              id="inputID"
              onInput={(e) => setSearch(e.target.value)}
              placeholder="Search By Ticket Id"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <span className="input-group-text" id="basic-addon1">
              <button className="btn">search</button>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {/* <button className="btn btn-light ms-1">+</button> */}
          {/* <button
            className="btn theme-bg text-white btnFilterhideMob"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Filter
          </button> */}
          <button
            className="btn btn-light float-end"
            data-bs-toggle="modal"
            data-bs-target="#ticketModal"
          >
            Create Ticket
          </button>
          <div
            className="modal fade"
            id="ticketModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Create Ticket
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={createApi} ref={formRef}>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label subject">
                        Subject
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          onInput={(e) => setSubject(e.target.value)}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label query">
                        Query
                      </label>
                      <div className="col-md-8 col-12">
                        <textarea
                          cols="4"
                          rows="5"
                          id="query"
                          name="query"
                          required
                          onInput={(e) => setQuery(e.target.value)}
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-4 col-12 col-form-label attachfile">
                        Attach file
                      </label>
                      <div className="col-md-8 col-12">
                        <input
                          type="file"
                          ref={attachRef}
                          name="attach"
                          className="form-control"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="float-end mt-2">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={closeRef}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary ms-1">
                        Create Ticket
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Filter />
      <SupportTable from={from} data={data} />
      <Pagination
        handlePageChange={handlePageChange}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default Support;
