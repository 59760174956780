import React, { useState } from "react";
import PaymentMilestoneTable from "./paymentMilestoneTable";
import OrderApi from "./orderApi";
import formatter from "../utils/formatter";
import Moment from "react-moment";

const PaymentTable = ({ data }) => {
  return (
    <>
      <div className="table table-responsive">
        <table className="table rowGray">
          <tr>
            <th>Sr. No.</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Date</th>
          </tr>
          {data.map((item, index) => {
            return (
              <>
                <tr>
                  <td>{item.id}</td>
                  <td>{formatter.format(item.amount)}</td>
                  <td>{item.type}</td>
                  <td className='text-nowrap'><Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment></td>
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default PaymentTable;
