import React, { useEffect, useState } from "react";
import UserProfile from "../userProfile/index";
import AllRequest from "./allRequest";
import "react-calendar/dist/Calendar.css";
import Filter from "../filter/Filter";
import Filters from "../filters/index";
import Pagination from "../component/Pagination";
import { toast } from "react-toastify";
import { toasterUpdate } from "../utils/toasterUpdate";
import { baseUrl } from "../utils/baseUrl";

function RequestForQuote() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [from, setFrom] = useState(1);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/rfq/seller?page=${currentPage}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    setData(() => result.data.data);
    setLastPage(result.data.last_page);
    setFrom(() => result.data.from);
    toast.dismiss(toastID);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchApi();
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <UserProfile title="Awaiting Response" />
      <div className="row mt-4">
        <div className="col-12">
          <div className="input-group mb-3 topSerrch21">
            <input
              type="text"
              className="form-control"
              id="inputID"
              placeholder=""
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <span className="input-group-text" id="basic-addon1">
              <img src="../images/search.png" height={18} />
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {/* <button
            className="btn  theme-bg text-white btnFilterhideMob"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Filter
          </button> */}
          {/* <button className="btn btn-light ms-1"  data-bs-toggle="modal" data-bs-target="#exampleModal">+</button> */}
        </div>
      </div>
      <Filters />
      <Filter />
      <AllRequest from={from} request={data} />
      <Pagination
        handlePageChange={handlePageChange}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </>
  );
}

export default RequestForQuote;
