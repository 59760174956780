const MenuItems = [
  {
    id: "1",
    name: "Dashboard",
    nameOrg: "Dashboard",
    imgsrc: "/images/New_Menu_icons/darhboard.png",
    imgsrc2: "/images/New_Menu_icons/darhboard2.png",
    visitLink: "/",
  },

  {
    id: "2",
    name: "Orders",
    nameOrg: "Orders",
    imgsrc: "/images/New_Menu_icons/Bag.png",
    imgsrc2: "/images/New_Menu_icons/Bag2.png",
    visitLink: "/orders",
  },
  {
    id: "3",
    name: "AwaitingResponse",
    nameOrg: "Awaiting Responses",
    imgsrc: "/images/New_Menu_icons/File_dock.png",
    imgsrc2: "/images/New_Menu_icons/File_dock2.png",
    visitLink: "/request-for-quote",
  },
  {
    id: "5",
    name: "Catalouge",
    nameOrg: "Product Catalouge",
    // SubMenu1:"Create Categories",
    // SubMenu2:"Create Brands",
    imgsrc: "/images/New_Menu_icons/Book_check.png",
    imgsrc2: "/images/New_Menu_icons/Book_check2.png",
    visitLink: "/products",
    downArrow: "/images/arrowDownWhite.png",
    downArrowColor: "/images/arrowDown-color.png",
  },
  {
    id: "4",
    name: "Payments",
    nameOrg: "Payments",
    imgsrc: "/images/New_Menu_icons/Money.png",
    imgsrc2: "/images/New_Menu_icons/Money2.png",
    visitLink: "/payments",
  },
  {
    id: "6",
    name: "Loan",
    nameOrg: "Loan",
    imgsrc: "/images/New_Menu_icons/Wallet_alt.png",
    imgsrc2: "/images/New_Menu_icons/Wallet_alt2.png",
    visitLink: "/loan",
  },
  {
    id: "7",
    name: "Support",
    nameOrg: "Support",
    imgsrc: "/images/New_Menu_icons/Ticket.png",
    imgsrc2: "/images/New_Menu_icons/Ticket2.png",
    visitLink: "/support",
  },
  {
    id: "8",
    name: "Address",
    nameOrg: "Address",
    imgsrc: "/images/New_Menu_icons/Ticket.png",
    imgsrc2: "/images/New_Menu_icons/Ticket2.png",
    visitLink: "/address",
  },
  {
    id: "9",
    name: "Roles",
    nameOrg: "Roles",
    imgsrc: "/images/New_Menu_icons/User_add.png",
    imgsrc2: "/images/New_Menu_icons/User_add2.png",
    visitLink: "/user",
  },
  // ,
  // {
  //     id: "9",
  //     name: "Reports",
  //     nameOrg: "Reports",
  //     imgsrc: "/images/New_Menu_icons/Desk.png",
  //     imgsrc2: "/images/New_Menu_icons/Desk2.png",
  //     visitLink:"/Reports"
  // }
];

export default MenuItems;
