import React from 'react'
import UserProfile from '../userProfile'

const CompanyDetails = () => {
  return (
    <>
      <UserProfile />
      <h4 style={{fontWeight:"400", marginTop:"31px",marginBottom:"25px",color:"gray"}}>Buyer Details</h4>

      <div className='buyrDDl'>
        <h4>General Information</h4>

        <div className='row'>
            <div className='col-md-6 col-12'>
                <div className='table cmpnyaddTabl'>
                    <table className='table'>
                        <tr>
                            <td>Company Name</td>
                            <td>Shakti Trader</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>BN 205 Noida 65</td>
                        </tr>
                        <tr>
                            <td>Contact Number</td>
                            <td>7503063585</td>
                        </tr>
                        <tr>
                            <td>Secondary Contact</td>
                            <td>NA</td>
                        </tr>
                        <tr>
                            <td>Email Id</td>
                            <td>Shaktitrader@gmail.com</td>
                        </tr>
                        <tr>
                            <td>PAN Number</td>
                            <td>HTMPK0825B</td>
                        </tr>
                        <tr>
                            <td>TAN Number</td>
                            <td>DNJBAJSBFIAB</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className='col-md-6 col-12'>

            <div className='table cmpnyaddTabl'>
                    <table className='table'>
                        <tr>
                            <td>Udhayan Aadhar No </td>
                            <td>JASJNAISJ</td>
                        </tr>
                        <tr>
                            <td>Drug Liscense No</td>
                            <td>DNJBAJSBFIAB</td>
                        </tr>
                        <tr>
                            <td>Explosive Lisecense No</td>
                            <td>DNJBAJSBFIAB</td>
                        </tr>
                        <tr>
                            <td>Prohibition Excise</td>
                            <td>DNJBAJSBFIA</td>
                        </tr>
                        
                    </table>
                </div>
            </div>
        </div>
      </div>

      <button className='btn btn-primary mt-3'>Contact to comapny</button>
    </>
  )
}

export default CompanyDetails
