// import { Add, AddSharp, ExpandMore, PlusOneTwoTone, Search, Upload, UploadFile } from '@mui/icons-material';

// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

import UserProfile from "../userProfile/index";
import { MdAdd, MdExpandMore } from "react-icons/md";
import { BiExpand, BiSearch, BiUpload } from "react-icons/bi";
import { FormCheck, FormGroup } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { toasterUpdate } from "../utils/toasterUpdate";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const ProductsEdit = () => {
  const [catalog, setcatalog] = useState([]);
  const [categories, setcategories] = useState([]);
  const [sub, setsub] = useState([]);
  const [units, setunits] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const [create, setCreate] = useState({});
  const [base_image, setbase_image] = useState(null);
  const [additional_files, setadditional_files] = useState([]);
  const [documents, setdocuments] = useState([]);

  const baseRef = useRef(null);
  const additionalRef = useRef(null);
  const documentRef = useRef(null);

  const storeApi = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_web");

    const formData = new FormData();

    formData.append("catalogue_id", create.catalogue_id);
    formData.append("sku", create.sku);
    formData.append("name", create.name);
    formData.append("unit", create.unit);
    formData.append("description", create.description);
    formData.append("price_discount", create.catalogue_id);
    formData.append("is_price_discount", create.catalogue_id);
    formData.append("is_mrp_product", create.is_mrp_product);
    formData.append("stock", create.stock);
    formData.append("quantity", create.quantity);
    formData.append("min_price", create.min_price);
    formData.append("gst", create.gst);
    formData.append("min_quantity", create.min_quantity);
    formData.append("is_default_price", create.is_default_price);
    formData.append("default_price", create.default_price);
    formData.append("max_price", create.max_price);

    formData.append("base_image", base_image);

    additional_files.forEach((item) => {
      formData.append("additional_files[]", item);
    });

    documents.forEach((item) => {
      formData.append("documents[]", item);
    });

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/product/${create.id}/edit`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }
    navigate("/products");
    toasterUpdate(toastID, result.message, "success");
  };

  const fetchUnit = async (id) => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/available/${id}/unit`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }
    setunits(() => result.data);
  };

  const fetchCategory = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/available/catalogs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    setcatalog(() => result.data);
    toast.dismiss(toastID);
  };

  const fetchCataloguesApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/available/catalogs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    setcatalog(() => result.data);
    toast.dismiss(toastID);
  };

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/product/${id}/edit`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    setCreate(() => result.data);
    fetchUnit(result.data.catalogue_id)
    fetchCategory()
    toast.dismiss(toastID);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "image/webp") {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 200000) {
          setbase_image(null);
          baseRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 200kb."
          );
        } else {
          setbase_image(file);
        }
      };
    } else {
      setbase_image(null);
      baseRef.current.value = null;
      toast.error("Please select a valid WebP image format.");
    }
  };

  const handleDocumentsChange = (event) => {
    setdocuments(Array.from(event.target.files));
  };

  const handleAdditionalFilesChange = (event) => {
    setadditional_files(Array.from(event.target.files));
  };

  useEffect(() => {
    fetchCataloguesApi();
    fetchApi();
  }, []);

  return (
    <>
      <UserProfile />

      <form className="profile_page" onSubmit={storeApi}>
        <div onSubmit={storeApi}>
          <h4>General Information</h4>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Catalog *</label>
              <select
                className="form-select"
                required
                onChange={(e) =>
                  setCreate(() => {
                    const catalogID = e.target.value;
                    if (catalogID) {
                      fetchUnit(catalogID);
                    }
                    return { ...create, catalogue_id: catalogID };
                  })
                }
              >
                {catalog && <option value={""}>Select Catalog</option>}
                {catalog.map((item, index) => {
                  return (
                    <option selected={create.catalogue_id === item.id} key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-control mb-4">
              <label>Product Name *</label>
              <input
                type="text"
                required
                value={create.name}
                onInput={(e) => setCreate({ ...create, name: e.target.value })}
                placeholder="product name"
              />
              {/*              <BiSearch /> */}
            </div>
            {/*<div className="form-control mb-4">
              <label>Brand</label>
              <select className="form-select">
                <option selected>Ex Camlin</option>
                <option value="1">Designation 1</option>
                <option value="2">Designation 2</option>
                <option value="3">Designation 3</option>
              </select>
            </div>*/}
          </div>
          {/*<div className="d-flex">
            <div className="form-control mb-4">
              <label>Category</label>
              <select className="form-select" onChange={(e) => setCreate({...create, category_id})}>
                {categories.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
            <div className="form-control mb-4">
              <label>Sub Category</label>
              <select className="form-select">
                {sub.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
              <MdExpandMore />
            </div>
            </div>*/}
          <div className="form-control mb-4">
            <label>Description</label>
            <textarea
              rows="6"
              value={create?.description}
              placeholder="Type Here"
              onInput={(e) =>
                setCreate({ ...create, description: e.target.value })
              }
            />
          </div>
        </div>
        <div>
          <h4>Price Information</h4>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Product Price *</label>
              <input
                type="number"
                min={0}
                value={create.default_price}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, default_price: e.target.value })
                }
                }
                required
              />
            </div>
            <div className="form-control mb-4">
              <label>Product GST % *</label>
              <input
                type="number"
                min={0}
                value={create.gst}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, gst: e.target.value })
                }}
                required
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Maximum Price *</label>
              <input
                type="number"
                min={0}
                value={create.max_price}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, max_price: e.target.value })
                }
                }
                required
              />
            </div>
            <div className="form-control mb-4">
              <label>Landed Cost</label>
              <input
                type="number"
                min={0}
                value={create.landed_cost}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, landed_cost: e.target.value })
                }
                }
                required
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Minimum Quantity For RFQ *</label>
              <input
                type="number"
                min={0}
                value={create.min_quantity}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, min_quantity: e.target.value })
                }
                }
                required
              />
            </div>
            <div className="form-control mb-4">
              <label>Minimum Price For RFQ *</label>
              <input
                type="number"
                min={0}
                value={create.min_price}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, min_price: e.target.value })
                }
                }
                required
              />
            </div>
          </div>

          {/*<FormCheck
            checked
            label="Check If You Want To Enable Auto RFQ Replies"
              />*/}
        </div>
        <div>
          <h4>Inventory</h4>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>SKU</label>
              <input
                type="text"
                value={create.sku}
                onInput={(e) => setCreate({ ...create, sku: e.target.value })}
                required
              />
            </div>
            <div className="form-control mb-4">
              <label>Stock Availability</label>
              <select
                className="form-select"
                required
                onChange={(e) =>
                  setCreate({ ...create, stock: e.target.value })
                }
              >
                <option>Select</option>
                <option value={1} selected={create.stock === 1 ? true : false}>available</option>
                <option value={0} selected={create.stock === 0 ? true : false}>not available</option>
              </select>
            </div>
          </div>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Inventory</label>
              <input
                type="number"
                min={0}
                value={create.quantity}
                onInput={(e) => {
                  e.target.value = Math.abs(e.target.value)
                  setCreate({ ...create, quantity: e.target.value })
                }
                }
                required
              />
            </div>
            <div className="form-control mb-4">
              <label>Select Unit</label>
              <select
                className="form-select"
                onChange={(e) => setCreate({ ...create, unit: e.target.value })}
              >
                <option>Select</option>
                {units.map((item) => (
                  <option selected={create.unit === item} key={item.toString()} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            {/*<div className="form-control mb-4">
              <label>Relatable Product</label>
              <input type="text" name="product" />
              <BiSearch />
            </div>*/}
          </div>
        </div>
        <div>
          <h4>Product Images</h4>
          <div className="form-control mb-4">
            <label>Primary image</label>
            <input
              ref={baseRef}
              type="file"
              name="url"
              class="form-control"
              accept="image/webp"
              onChange={handleFileChange}
            />
            <BiUpload className="icon" />
          </div>
          <img
            src={create.base_image || "/images/no-image.png"}
            alt={create.base_image}
            onError={(e) => {
              e.target.src = "/images/no-image.png";
            }}
            style={{ maxHeight: '120px' }}
            className="preview"
          />
          <div className="form-control mb-4">
            <label>Additional Images</label>
            <input
              ref={additionalRef}
              class="form-control"
              type="file"
              accept="image/webp"
              multiple={true}
              onChange={handleAdditionalFilesChange}
            />
            <BiUpload className="icon" />
          </div>
          <div className="d-flex gap-3 mb-3">
            {create?.additional_images?.map((item) => {
              return (
                <div className="image_box">
                  <img
                    src={item.url || "/images/no-image.png"}
                    alt={item.url}
                    className="preview"
                    style={{ maxHeight: '120px', borderWidth: 1, borderStyle: 'solid' }}

                    onError={(e) => {
                      e.target.src = "/images/no-image.png";
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="form-control mb-4">
            <label>Documents</label>
            <input
              ref={documentRef}
              class="form-control"
              type="file"
              accept="application/pdf"
              multiple={true}
              onChange={handleDocumentsChange}
            />
            <BiUpload className="icon" />
          </div>
          <div className="d-flex gap-3 mb-3">
            {create?.documents?.map((item) => {
              return (
                <div className="image_box">
                  <a href={item.url} target="_blank">
                    <img
                      src="/images/no-file.png"
                      alt=""
                      style={{ maxHeight: '120px', borderWidth: 1, borderStyle: 'solid' }}
                      onError={(e) => {
                        e.target.src = "/images/no-file.png";
                      }}
                      className="preview p-3"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h4>SEO</h4>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>URL</label>
              <input
                type="text"
                name="url"
                placeholder="Ex www.google.com/facebook"
              />
            </div>
            <div className="form-control mb-4">
              <label>Meta Title</label>
              <input type="text" name="title" />
            </div>
          </div>
          <div className="form-control mb-4">
            <label>Meta Decription</label>
            <textarea rows="6" placeholder="Type Here " name="decription" />
          </div>
        </div>
        {/*<div>
          <h4>Add Attributes</h4>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Attributes</label>
              <input type="text" name="attributes" />
            </div>
            <div className="form-control mb-4">
              <label>value</label>
              <input type="text" name="value" />
            </div>
          </div>
          <button className="add">
            Add More <MdAdd />
          </button>
        </div>}
        {/*<div>
          <h4>Related Products</h4>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label>Attributes</label>
              <input type="text" name="attributes" />
            </div>
            <div className="form-control mb-4">
              <label>value</label>
              <input type="text" name="value" />
            </div>
          </div>
          <button className="add">
            Add More <MdAdd />
          </button>
        </div>*/}

        <div className="form-control mt-4 text-end">
          <button className="continue" type="submit">
            Create
          </button>
        </div>
      </form>
    </>
  );
};

export default ProductsEdit;
