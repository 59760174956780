import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const AllOrder = ({ order, from }) => {
  return (
    <>
      <div className="row mt-4">
        <div className="col-12">
          <div className="table table-responsive table-borderless">
            <table className="table rowGray">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th className='text-nowrap'>Invoice No.</th>
                  <th>Name</th>
                  <th className='text-nowrap'>Payment Mode</th>
                  <th>Status</th>
                  <th className='text-nowrap'>Date Created</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {order.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{from + index}</td>
                      <td>{item.order_id} </td>
                      <td className='text-nowrap'>{item.buyer?.vendor.company}</td>
                      <td className='text-nowrap'>{item.payment_mode}</td>
                      <td className='text-nowrap'>{item.order_status}</td>
                      <td className='text-nowrap'><Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment></td>
                      <td className="text-center">
                        <Link
                          className="btn theme-bg text-white"
                          to={"/orders/" + item.id}
                        >
                          {/* data-bs-toggle="modal" data-bs-target={"#idnum" + curElm.id} */}

                          <span>View</span>
                        </Link>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllOrder;
