import "./App.css";
import "./responsive.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import Navbar from "./navbar/index";
import Dashboard from "./dashboard/index";
import Orders from "./orders/index";
import RequestForQuote from "./requestForQuote/index";
import Payments from "./payments/index";
import Catalouge from "./catalouge/index";
import Loan from "./loan/index";
import Support from "./support/index";
import OrderView from "./orders/orderView";
import FaqResponse from "./faqResponse";
import UploadBulk from "./uploadBulk";
import AddProducts from "./addProduct";
import CreateCategory from "./createCategories";
import CreateBrand from "./createBrand";
import User from "./user/index";
import SubAccount from "./subAccount/index";
import Mobilenav from "./mobilenav/Mobilenav";
import ResponseRFQ from "./responseRFQ";
import CompanyDetails from "./companyDetails";
import Register from "./Auth/Register";

import Filter from "./filter/Filter";
import Products from "./newpages/Products";
import ProductsEdit from "./catalouge/ProductsEdit";
import RFQ from "./newpages/RFQ";
import OrderDetail from "./newpages/OrderDetail";
import { useEffect, useState } from "react";
import Login from "./Auth/Login";

import { AuthContext } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SupportDetail from "./support/SupportDetail";
import Address from "./address/Address";
import Profile from "./user/Profile";

function App() {
  const [auth, setAuth] = useState(null);
  const [login, setLogin] = useState(false);

  const checkLogin = () => {
    const isLogin = localStorage.getItem("token_web");
    if (isLogin) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <ToastContainer style={{ zIndex: 9999999 }} />
      <BrowserRouter>
        {login && (
          <div className="App">
            <Mobilenav />
            <div className="main-Conatiner">
              <div className="row">
                <div
                  className="col-lg-3 col-md-12 col-sm-12 col-xs-12"
                  id="SideMenuHS"
                >
                  <Navbar />
                </div>
                {/* <img id="toogleIcon" src='../images/toggleMenuIcon.png' alt="toggleMenuIcon" /> */}

                <div className="col-md-9">
                  <div className="rightSideOutContainer">
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/orders" element={<Orders />} />
                      <Route
                        path="/request-for-quote"
                        element={<RequestForQuote />}
                      />
                      <Route path="/payments" element={<Payments />} />
                      <Route path="/products" element={<Catalouge />} />
                      <Route path="/product/add" element={<Products />} />
                      <Route
                        path="/product/:id/edit"
                        element={<ProductsEdit />}
                      />
                      <Route path="/loan" element={<Loan />} />
                      <Route path="/support" element={<Support />} />
                      <Route
                        path="/support/:id/detail"
                        element={<SupportDetail />}
                      />
                      <Route path="/uploadBulk" element={<UploadBulk />} />
                      <Route
                        path="/request-for-quote/faqResponse/:id"
                        element={<RFQ />}
                      />
                      <Route
                        path="/createCategory"
                        element={<CreateCategory />}
                      />
                      <Route path="/createBrand" element={<CreateBrand />} />
                      <Route path="/user" element={<User />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/addsubaccount" element={<SubAccount />} />
                      <Route
                        path="/request-for-quote/responseRFQ"
                        element={<ResponseRFQ />}
                      />
                      <Route
                        path="/companyDetails"
                        element={<CompanyDetails />}
                      />

                      <Route path="/filter" element={<Filter />} />

                      <Route path="/address" element={<Address />} />

                      {/* new routes */}
                      <Route path="/rfq" element={<RFQ />} />
                      <Route path="/orders/:id" element={<OrderDetail />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--end of main-Conatiner--> */}
          </div>
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={!login && <Login />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
