import React from "react";
import { NavLink } from "react-router-dom";

const AllMenu = ({ menuItem }) => {
  return (
    <>
      {menuItem.map((item, index) => {
        return (
            <li className={item.id} id={item.name + "toggle"} key={index}>
              <NavLink to={item.visitLink} className={item.name + "toggle"}>
                <img
                  className="icon1to2-f"
                  src={item.imgsrc}
                  alt={item.name}
                  height="23px"
                />
                <img
                  className="icon1to2-s"
                  src={item.imgsrc2}
                  alt={item.name}
                  height="23px"
                />
                <span>{item.nameOrg}</span>
              </NavLink>
            </li>
        );
      })}
    </>
  );
};

export default AllMenu;
