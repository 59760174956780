import React from "react";

const OrderCustomersCard = ({ orderCustomer }) => {
  return (
    <>
      <div className="col-md-4 mb-2 toOrCus">
        {orderCustomer.map((item, index) => {
          return (
              <div className="TwoCard position-relative" key={index}>
                <div>
                  <div className="TowCard-icon">
                    <img src={item.imgsrc} alt={item.name} />
                  </div>
                </div>
                <div className="ordAndNum">
                  <h4>{item.total}</h4>
                  <p>{item.name}</p>
                </div>
              </div>
          );
        })}
      </div>
    </>
  );
};

export default OrderCustomersCard;
