import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const SupportTable = ({ data, from }) => {
  return (
    <>
      <div
        className="table table-responsive mt-4"
        style={{ minHeight: "500px" }}
      >
        <table className="table rowGray">
          <thead>
            <tr>
              <th className="text-nowrap">Sr. No.</th>
              <th className="text-nowrap">Ticket Id</th>
              <th>Date</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{from + index}</td>
                  <td className="text-nowrap">{item.ticket_id}</td>
                  <td className="text-nowrap"><Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment></td>
                  <td className="text-nowrap">{item.subject}</td>
                  <td className="text-nowrap">{item.status}</td>
                  <td>
                    <Link
                      to={`${item.id}/detail`}
                      className="btn theme-bg text-white float-end"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SupportTable;
