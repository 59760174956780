import React, { useState, useEffect } from 'react';

const DynamicGreeting = () => {
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const getCurrentGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting('Good morning');
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting('Good afternoon');
      } else {
        setGreeting('Good evening');
      }
    };

    getCurrentGreeting(); // Set the initial greeting

    // Update the greeting every minute
    const interval = setInterval(getCurrentGreeting, 60000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h3>{greeting}</h3>
    </div>
  );
};

export default DynamicGreeting;
