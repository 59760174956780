import React from 'react'
import UserProfile from '../userProfile'

const SubAccount = () => {
  return (
    <>
        <UserProfile />
        <h4 className='my-4'style={{fontWeight:'400',color:'#767676',fontFamily:"'Inter', sans-serif !important"}}>Create Sub Account</h4>
        <form className='mb-4'>
        <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label">Name</label>
            <div class="col-sm-8">
            <input type="text"  style={{background:"#F4F4F4"}} class="form-control" id="name" />
            </div>
        </div>
        <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label">Role</label>
            <div class="col-sm-8">
                <select  style={{background:"#F4F4F4"}} class="form-select" aria-label="Default select example">
                    <option selected></option>
                    <option value="1">Role 1</option>
                    <option value="2">Role 1</option>
                    <option value="3">Role 1</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label">User Id</label>
            <div class="col-sm-8">
            <input style={{background:"#F4F4F4"}} type="text" class="form-control" id="role" />
            </div>
        </div>
        <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
            <div class="col-sm-8">
            <input style={{background:"#F4F4F4"}} type="password" class="form-control" id="pass" />
            </div>
        </div>
        <h4 className='mb-4 mt-5'style={{fontWeight:'400',color:'#767676',fontFamily:"'Inter', sans-serif !important"}}>Access Grant</h4>
        <div className='row'>
            <div className='col-md-4'>
            <div className=' mb-3'>
                    <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                        <label className="form-check-label float-start" for="add1">Role 1</label>
                        <input className="form-check-input  float-end" type="checkbox" role="switch" id="add1" />
                    
                    </div>
                </div>
                <div className=' mb-3'>
                    <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                        <label className="form-check-label float-start" for="add2">Role 2</label>
                        <input className="form-check-input  float-end" type="checkbox" role="switch" id="add2" />
                    
                    </div>
                </div>
                <div className=' mb-3'>
                    <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                        <label className="form-check-label float-start" for="add3">Role 3</label>
                        <input className="form-check-input  float-end" type="checkbox" role="switch" id="add3" />
                    
                    </div>
                </div>
                <div className=' mb-3'>
                    <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                        <label className="form-check-label float-start" for="add4">Role 4</label>
                        <input className="form-check-input  float-end" type="checkbox" role="switch" id="add4" />
                    
                    </div>
                </div>
            </div>
            <div className='col-md-4 offset-md-2'>
            <div className=' mb-3'>
                    <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                        <label className="form-check-label float-start" for="add5">Role 5</label>
                        <input className="form-check-input  float-end" type="checkbox" role="switch" id="add5" />
                    
                    </div>
                </div>
                <div className=' mb-3'>
                    <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                        <label className="form-check-label float-start" for="add6">Role 6</label>
                        <input className="form-check-input  float-end" type="checkbox" role="switch" id="add6" />
                    
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <button className='btn btn-primary float-end'>Create</button>
            </div>
        </div>
        </form>
    </>
  )
}

export default SubAccount